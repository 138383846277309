import { Link } from "gatsby";
import React, { useContext } from "react";
import CartAddToCart from "../../components/partials/cart-add-to-cart";
import CartContext from "../../context/cart-context";
import { formatPrice } from "../../utilities/price";

const RecommendedProductsCta = () => {
  const cartContext = useContext(CartContext);
  const recommendedProducts = cartContext.recommendedProducts()
    ? cartContext.recommendedProducts()
    : [];

  return (
    <>
      <div
        className={`${
          cartContext.totals.countItems &&
          (recommendedProducts.length ||
            cartContext.checkoutInfo.shippingMethod !== "pickup")
            ? "flex flex-wrap"
            : "hidden"
        } mt-8`}
      >
        <div className="pb-4 w-full text-grey-darkest">
          <div className="flex flex-wrap md:h-full">
            {recommendedProducts.map((product) => {
              const price = +product.node.product.salePrice
                ? +product.node.product.salePrice
                : +product.node.product.basePrice;
              return (
                <div
                  className="py-4 md:p-4 w-full h-full md:w-1/3 flex"
                  key={product.node.product.sku}
                >
                  <div className="max-w-sm rounded overflow-hidden shadow-md">
                    <Link
                      className="inline-block h-64 w-full cursor-pointer"
                      style={{
                        backgroundImage: `url(${product.node.featuredImage.node.localFile.publicURL})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      to={`/${product.node.slug}/`}
                    />
                    <div className="px-6 py-5 -mt-2">
                      <Link
                        className="font-bold text-xl no-underline"
                        to={`/${product.node.slug}/`}
                      >
                        {product.node.title}
                      </Link>
                      <p className="text-grey-darker text-base pt-2">
                        {product.node.product.cartCalloutText}
                      </p>
                    </div>
                    <div className="px-6 md:py-4">
                      <p className="pt-3">
                        <span
                          className={`${
                            price === +product.node.product.basePrice
                              ? "hidden "
                              : ""
                          }line-through text-lg pr-2`}
                        >
                          {formatPrice(product.node.product.basePrice)}
                        </span>
                        <span className="text-xl">{formatPrice(price)}</span>
                      </p>
                    </div>
                    <div className="px-6 pb-4 md:pt-4">
                      <div className="flex mt-2 md:mt-6 items-center justify-between w-full">
                        <CartAddToCart
                          product={product}
                          buttonClasses={`bg-${process.env.THEME_COLOR_PRIMARY} appearance-none border rounded ml-2 py-2 px-3 text-${process.env.THEME_COLOR_PRIMARY}-lightest leading-tight focus:outline-none focus:ring h-10 uppercase`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedProductsCta;
